import styled from 'styled-components';
import { device } from 'styles/devices';

export const StyledLoader = styled.div`
  position: absolute;
  z-index: 100;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100vw;
  height: 100vh;
  background-color: ${props => props.theme.primaryDark};
`;

export const Logo = styled.img`
  width: 160px;
  height: auto;

  @media ${device.tablet} {
    width: 200px;
  }

  @media ${device.laptop} {
    width: 260px;
  }

  @media ${device.laptopL} {
    width: 340px;
  }
`;
