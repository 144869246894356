import { StyledLoader, Logo } from './Loader.styled';
import logo from 'images/logo_white.png';

const Loader = () => {
  return (
    <StyledLoader>
      <Logo src={logo} alt="Suslov and Partners Law Firm" />
    </StyledLoader>
  );
};

export default Loader;
