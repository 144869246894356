import { Suspense, useEffect, lazy } from 'react';
import { Route, Routes } from 'react-router-dom';
import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import translationUA from '../data/ua/translation.json';
import translationEN from '../data/en/translation.json';
import WebFont from 'webfontloader';
import Loader from './Loader';

const HomePage = lazy(() => import('../pages/Home'));
const TeamPage = lazy(() => import('../pages/Team'));
const PracticesPage = lazy(() => import('../pages/Practices'));
const BlogPage = lazy(() => import('../pages/Blog'));
const BlogItemPage = lazy(() => import('../pages/BlogItem'));
const ContactsPage = lazy(() => import('../pages/Contacts'));

const resources = {
  ua: {
    translation: translationUA,
  },
  en: {
    translation: translationEN,
  },
};

i18n.use(initReactI18next).init({
  resources,
  lng: "ua",
  fallbackLng: "ua",
  interpolation: {
    escapeValue: false,
  },
});

export const App = () => {
  useEffect(() => {
    WebFont.load({
      google: {
        families: ['Open Sans'],
      },
    });
  }, []);

  return (
    <Suspense fallback={<Loader />}>
      <Routes>
        <Route path="/" element={<HomePage />} />
        <Route path="/practices" element={<PracticesPage />} />
        <Route path="/team" element={<TeamPage />} />
        <Route path="/blog" element={<BlogPage />} />
        <Route path="/blog/:id" element={<BlogItemPage />} />
        <Route path="/contacts" element={<ContactsPage />} />
        <Route path="*" element={<HomePage />} />
      </Routes>
    </Suspense>
  );
};
