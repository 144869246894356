const size = {
  onlymobile: '424px',
  mobile: '425px',
  tablet: '768px',
  laptop: '1024px',
  laptopL: '1440px',
};

export const device = {
  mobile: `(min-width: ${size.mobile})`,
  onlymobile: `(max-width: ${size.onlymobile})`,
  tablet: `(min-width: ${size.tablet})`,
  laptop: `(min-width: ${size.laptop})`,
  laptopL: `(min-width: ${size.laptopL})`,
};
