import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';
import { ThemeProvider } from 'styled-components';
import { App } from 'components/App';
import ScrolltoTop from 'components/ScrolltoTop';
import { theme } from 'styles/theme';
import './index.css';

ReactDOM.createRoot(document.getElementById('root')).render(
  <BrowserRouter basename="/">
    <ThemeProvider theme={theme}>
      <ScrolltoTop />
      <App />
    </ThemeProvider>
  </BrowserRouter>
);
